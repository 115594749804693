import React from 'react'
import classNames from 'classnames'

import iconEdit from '../../../assets/images/icon-change-key-name.svg'
import iconDelete from '../../../assets/images/icon-trash-active.svg'
import iconDeleteDisable from '../../../assets/images/icon-trash-disable.svg'
import iconLock from '../../../assets/images/icon-lock.svg'
import iconUnlock from '../../../assets/images/icon-unlock.svg'
import { TxtAction } from './interface'

interface PropsAction {
  handleShowDialog?: (value: TxtAction) => void
  isBlock?: boolean
  handleShowEditInfoDialog?: () => void
}

const RowActionUserList: React.FunctionComponent<PropsAction> = ({
  handleShowDialog = () => null,
  isBlock,
  handleShowEditInfoDialog = () => null,
}) => {
  const eDivClasses = classNames(
    `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
  )

  return (
    <div className="relative h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl">
      <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleShowEditInfoDialog}>
        <div>
          <img src={iconEdit} alt="IconTrashActive" />
        </div>
        <p className="ml-3">Edit</p>
      </div>
      <hr className="my-1 border-t-neutral-7" />
      {!isBlock ? (
        <div
          className={`${eDivClasses} justify-start mb-1 flex`}
          onClick={() => handleShowDialog(TxtAction.BLOCK)}
        >
          <div>
            <img src={iconLock} alt="IconTrashInActvie" />
          </div>
          <p className="ml-3">Block1</p>
        </div>
      ) : (
        <div
          className={`${eDivClasses} justify-start mb-1 flex`}
          onClick={() => handleShowDialog(TxtAction.UNBLOCK)}
        >
          <div>
            <img src={iconUnlock} alt="IconTrashInActvie" />
          </div>
          <p className="ml-3">Unblock</p>
        </div>
      )}
      <hr className="my-1 border-t-neutral-7" />
      {isBlock ? (
        <div
          className={`${eDivClasses} justify-start mb-1 flex`}
          onClick={() => handleShowDialog(TxtAction.DELETE)}
        >
          <div>
            <img src={iconDelete} alt="IconTrashInActive" />
          </div>
          <p className="ml-3">Delete</p>
        </div>
      ) : (
        <div className={`${eDivClasses} justify-start mb-1 flex cursor-not-allowed`}>
          <div>
            <img src={iconDeleteDisable} alt="IconTrashInActive" />
          </div>
          <p className="ml-3 text-neutral-6">Delete</p>
        </div>
      )}
    </div>
  )
}

export default RowActionUserList
