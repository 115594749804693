/* eslint-disable react/jsx-key */
import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import ButtonBase from '../../../../components/partials/button'
import BaseDialog from '../../../../components/dialog'
import { GET_CHATLIO_CONVERSATION_DETAIL } from '../../../../queries/adminPortal'
import { useNavigate, useParams } from 'react-router-dom'

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  groupId: any
}

const styleDetailConversationItem = {
  content: {
    width: '90%',
    maxWidth: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}



const ConversationItemDialog: React.FunctionComponent<PropsAction> = ({
  groupId,
  modalIsOpen,
  handleCloseDialog = () => null,
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [itemCloseChat, setItemCloseChat] = useState<any>(null)
  const [chatPayloadDetail, setChatPayloadDetail] = useState<any>(null)
  const { data: getChatlioConversationDetail } = useQuery(GET_CHATLIO_CONVERSATION_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      groupId: groupId,
    },
  })

  useEffect(() => {
    if (getChatlioConversationDetail) {
      const itemClose = getChatlioConversationDetail.getChatlioConversationDetail.find( (item: any) => item.type === "CHAT_TRANSCRIPT")
      if (itemClose) {
        const payloadObj = JSON.parse(itemClose.payload)
        setChatPayloadDetail(payloadObj)
        setItemCloseChat(itemClose)
      }
    }
  }, [getChatlioConversationDetail])
  const handleCloseModel = () => {
    if (id) {
      navigate('/chatlio')
    } else {
      handleCloseDialog(false)
    }
  }
  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleDetailConversationItem}>
      <React.Fragment>
        <div>
          <div className="max-h-[758px] w-[758px] p-1 pr-0 overflow-y-scroll cus-scrollbar ">
            <p className="mb-2 text-headline4">Conversation detail</p>
            <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
            {itemCloseChat && (
              <div className="content" dangerouslySetInnerHTML={{__html: chatPayloadDetail.htmlBody}}></div>
            )}
          </div>
          <hr className="mt-4 mb-2" />
          <ButtonBase
            type="submit"
            className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={() => {
              handleCloseModel()
            }}
            bgDisabled="bg-primary-shade3"
          >
            Close
          </ButtonBase>
        </div>
      </React.Fragment>
    </BaseDialog>
  )
}

export default ConversationItemDialog
