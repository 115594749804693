export type Simple = {
  jobId: string | null
}

export const tableHeaderConversation = [
  {
    styles: 'w-[10%] lg:pt-0',
    name: 'Date',
    label: 'date'
  },
  {
    styles: 'w-[12%] lg:pt-0',
    name: 'User',
    label: 'User'
  },
  {
    styles: 'w-[18%]',
    name: 'Page',
    label: 'Page'
  },
  {
    styles: 'lg:pt-0 w-[18%]',
    name: 'Location',
    label: 'location'
  },
  {
    styles: 'w-[32%]',
    name: 'Message',
    label: 'message'
  },
  {
    styles: 'w-[10%] lg:pt-0',
    name: 'Status',
    label: 'status'
  }
]

export type VariableFilters = {
  dateFrom?: string
  dateTo?: string
  limit: number
  page: number
}