import React, { useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import ChatlioConversationPart from './components'

// Tabs Array
const tabs: TabsType = [
  {
    label: 'Conversation',
    index: 1,
    Component: ChatlioConversationPart,
  },
]

const ChatlioHeader = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  return (
    <div>
      <Tabs
        needResponsive={true}
        selectedTab={selectedTab}
        pageName="Chatlio"
        onClick={setSelectedTab}
        tabs={tabs}
      />
    </div>
  )
}

export default ChatlioHeader
