import React from 'react'
import classNames from 'classnames'

import IconEdit from '../../assets/images/icon-change-key-name.svg'
import IconTrashActive from '../../assets/images/icon-trash-active.svg'

interface PropsAction {
  handleAction: () => void
  txt: string,
  action: string,
  icon?: any
  block?: boolean
}

const SingleRowActionBase: React.FunctionComponent<PropsAction> = ({
  txt,
  handleAction = () => null,
  action,
  icon,
  block
}) => {
  const eDivClasses = classNames(
    `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
  )

  const onHandleAction = () => {
    // console.log('onHandleAction')
    handleAction()
  }

  let iconAction = icon ?? IconTrashActive
  if (action === "edit") {
    iconAction = IconEdit
  }
  return (
    
    <div className="">
      {
        block ? (
          <div className={`${eDivClasses} cursor-not-allowed`}>
            <div>
              <img src={iconAction} alt={iconAction} />
            </div>
            <p className="ml-3 text-neutral-6">{txt}</p>
          </div>
        ) : (
          <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={onHandleAction}>
            <div>
              <img src={iconAction} alt={iconAction} />
            </div>
            <p className="ml-3">{txt}</p>
         </div>
        )
      }
      
    </div>
  )
}

export default SingleRowActionBase
