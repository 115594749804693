import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'

import IconLogo from '../../assets/images/logo-propeller-full.svg'
import Avatar from '../../assets/images/avatar-example.svg'
import iconDownArrow from '../../assets/images/icon-down-arrow.svg'
import iconUpArrow from '../../assets/images/icon-up-arrow.svg'
import ButtonSignIn from '../sign-in'
import ButtonSignOut from '../sign-out'
import { RootState } from '../../store'
import {
  CREATE_PRODUCTION_ACC,
  GET_STATUS_REQUEST_ACC,
  REQUEST_ACC_TO_PRODUCTION,
} from '../../queries/developerPortal'
import { notify } from '../toastify'
import { MESSAGES, STATUS } from '../../constants/messages'
import ButtonBase from '../partials/button'
import { clientProd } from '../../network/apolloClient'
import { ROLE } from '../../constants/common'
import { useSession } from '../../helpers/checkSession'
import { getDataToLocalStore, LocalStorageKey } from '../../helpers/localStorage'
import { CODE_TIMEOUT } from '../../constants/statusCode'
import { useWindowSize } from '../../helpers/useWindowSize'
import { screens } from '../../constants/screens'

const Header = () => {
  const { handleErrorSession, timeOutSession } = useSession()
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const dropDownMenuRef = useRef<HTMLUListElement>(null)
  const [isPopupDropDown, setIsPopupDropDown] = useState<boolean>(false)
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const [isActiveDropDown, setIsActiveDropDown] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>(Avatar)
  const [statusRequestAcc, setStatusRequestAcc] = useState<boolean>(false)

  const accessTokenGg = getDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG)
  const userInfo = getDataToLocalStore(LocalStorageKey.USER_INFO)
  const isRoleAdmin = userInfoState?.role?.name === ROLE.Admin
  const isRoleDeveloper = userInfoState?.role?.name === ROLE.Developer
  const isRoleDeveloperAccountManager = userInfoState?.role?.name === ROLE.DeveloperAccountManager
  const isRoleAccountManager = userInfoState?.role?.name === ROLE.AccountManager

  const windowSize = useWindowSize()
  const handleClick = () => {
    setIsPopup(!isPopup)
  }
  const handleClickDropDown = () => {
    setIsPopupDropDown(!isPopupDropDown)
  }
  const handleClickActiveDrop = () => {
    setIsActiveDropDown(true)
    setIsPopupDropDown(false)
  }
  const { data: statusRequestAccData, error } = useQuery(GET_STATUS_REQUEST_ACC, {
    skip: !userInfo || isRoleAdmin,
  })
  const [requestACCToProduction] = useMutation(REQUEST_ACC_TO_PRODUCTION)
  const [createProductionAcc] = useMutation(CREATE_PRODUCTION_ACC, {
    client: clientProd,
  })
  const handleAccountRequest = async () => {
    // set status request true to handle disable button request acc
    setStatusRequestAcc(true)
    const variableAccountRequest = {
      data: {
        isProduction: true,
      },
    }
    try {
      const dataResRequest: any = await requestACCToProduction({
        variables: variableAccountRequest,
      })
      const userData = dataResRequest?.data?.requestAccountToProduction
      const dataResCreateAcc = await createProductionAcc({
        variables: {
          data: {
            organizationName: userData?.organizationName,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            email: userData?.email,
            phoneNumber: userData?.phoneNumber,
            businessSponsorId: userData?.party?.businessSponsor?.businessSponsorId,
            businessSponsorFirstName: userData?.party?.businessSponsor?.businessSponsorFirstName,
            businessSponsorLastName: userData?.party?.businessSponsor?.businessSponsorLastName,
            businessSponsorEmail: userData?.party?.businessSponsor?.businessSponsorEmail,
            businessSponsorPhoneNumber:
              userData?.party?.businessSponsor?.businessSponsorPhoneNumber,
            partyId: userData?.partyId,
            kind: userData?.kind,
            requesterId: userData?.userId,
            gid: userInfoState?.gid,
            gsuiteUsername: userData?.gsuiteUsername,
            carrierId: userData?.carrierId,
            agencyId: userData?.agencyId,
            obligeeId: userData?.obligeeId,
          },
        },
      })
      if (dataResCreateAcc) {
        notify(MESSAGES.SUCCESS.S_REQUEST_ACC_TO_PRODUCTION, STATUS.SUCCESS)
      }
    } catch (error) {
      setStatusRequestAcc(false)
      handleErrorSession(error, MESSAGES.ERROR.E_REQUEST_ACC_TO_PRODUCTION)
    }
  }

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        menuRef &&
        !menuRef.current?.contains(e.target) &&
        btnRef &&
        !btnRef.current?.contains(e.target)
      ) {
        setIsPopup(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  useEffect(() => {
    const handleOutsideDropDownClick = (e: any) => {
      if (
        dropDownMenuRef &&
        !dropDownMenuRef.current?.contains(e.target) &&
        btnRef &&
        !dropDownRef.current?.contains(e.target)
      ) {
        setIsPopupDropDown(false)
      }
    }

    document.addEventListener('click', handleOutsideDropDownClick)

    return () => {
      document.removeEventListener('click', handleOutsideDropDownClick)
    }
  }, [isPopupDropDown])

  useEffect(() => {
    setAvatar(userInfoState?.avatar)
  }, [userInfoState])

  useEffect(() => {
    setStatusRequestAcc(statusRequestAccData?.getStatusRequestAccount)
  }, [statusRequestAccData])

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [error])

  return (
    <header className="w-full max-w-[1440px] flex items-center px-20 h-[72px] justify-between border-b border-b-neutral-6 bg-neutral-7-header-footer fixed z-20">
      <div className="flex items-center h-full">
        <NavLink to="/">
          <img src={IconLogo} alt="Logo" className="max-w-[110px] h-[40px] mr-5 md:ml-[-40px]" />
        </NavLink>
        <ul className="flex h-full text-body1 text-neutral-4 pl-[48px] md:pl-[28px]">
          <li
            onClick={() => setIsActiveDropDown(false)}
            className="relative flex items-center mx-3 hover:text-primary-shade2"
          >
            <NavLink
              className={({ isActive }) =>
                `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                  isActive && 'before:w-full text-primary-shade1'
                }`
              }
              end={true}
              to="/quick-start"
            >
              Quick start
            </NavLink>
          </li>
          <li
            onClick={() => setIsActiveDropDown(false)}
            className="relative flex items-center mx-3 hover:text-primary-shade2"
          >
            <NavLink
              className={({ isActive }) =>
                `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                  isActive && 'before:w-full text-primary-shade1'
                }`
              }
              to="/tech-solution"
            >
              Tech solution
            </NavLink>
          </li>
          {Number(windowSize.width) > screens.lg && (
            <>
              {accessTokenGg && (
                <>
                  <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/developer-docs"
                    >
                      Developer docs
                    </NavLink>
                  </li>
                  {(isRoleDeveloper || isRoleAccountManager || isRoleDeveloperAccountManager) && (
                    <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                      <NavLink
                        className={({ isActive }) =>
                          `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActive && 'before:w-full text-primary-shade1'
                          }`
                        }
                        to="/developer-portal"
                      >
                        Developer portal
                      </NavLink>
                    </li>
                  )}
                  {isRoleAdmin && (
                    <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                      <NavLink
                        className={({ isActive }) =>
                          `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActive && 'before:w-full text-primary-shade1'
                          }`
                        }
                        to="/admin-portal"
                      >
                        Admin portal
                      </NavLink>
                    </li>
                  )}
                   {isRoleAdmin && (
                    <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                      <NavLink
                        className={({ isActive }) =>
                          `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActive && 'before:w-full text-primary-shade1'
                          }`
                        }
                        to="/chatlio"
                      >
                        Chatlio
                      </NavLink>
                    </li>
                  )}
                  <li className="relative flex items-center mx-3 hover:text-primary-shade2">
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/my-account"
                    >
                      My account
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
          {Number(windowSize.width) > screens.md && Number(windowSize.width) <= screens.lg && (
            <>
              {accessTokenGg && (
                <>
                  <li
                    className="relative flex items-center mx-3 hover:text-primary-shade2"
                    onClick={() => setIsActiveDropDown(false)}
                  >
                    <NavLink
                      className={({ isActive }) =>
                        `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                          isActive && 'before:w-full text-primary-shade1'
                        }`
                      }
                      to="/developer-docs"
                    >
                      Developer docs
                    </NavLink>
                  </li>
                  {Number(windowSize.width) > screens.md && Number(windowSize.width) <= screens.lg && (
                    <>
                      <li className="relative py-2 pt-6 pl-3 pr-4">
                        <div
                          ref={dropDownRef}
                          onClick={handleClickDropDown}
                          className={`flex items-center cursor-pointer hover:text-primary-shade2 rounded-full before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                            isActiveDropDown ? 'before:w-11 mr-4 text-primary-shade1' : ''
                          }`}
                        >
                          More
                          {isPopupDropDown ? (
                            <img src={iconUpArrow} alt="iconUpArrow" width={20} height={20} />
                          ) : (
                            <img src={iconDownArrow} alt="iconDownArrow" width={20} height={20} />
                          )}
                        </div>
                        {isPopupDropDown && (
                          <ul
                            ref={dropDownMenuRef}
                            className="absolute mt-2 w-[198px] rounded-xl bg-neutral-8 p-3 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                          >
                            {(isRoleDeveloper ||
                              isRoleAccountManager ||
                              isRoleDeveloperAccountManager) && (
                              <li className="flex items-center mx-2 relative hover:text-primary-shade2 h-[40px]">
                                <NavLink
                                  onClick={handleClickActiveDrop}
                                  className={({ isActive }) =>
                                    ` hover:w-full ${
                                      isActive &&
                                      'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                    }`
                                  }
                                  to="/developer-portal"
                                >
                                  Developer portal
                                </NavLink>
                              </li>
                            )}
                            {isRoleAdmin && (
                              <li className="flex items-center mx-2 relative hover:text-primary-shade2 h-[40px]">
                                <NavLink
                                  onClick={handleClickActiveDrop}
                                  className={({ isActive }) =>
                                    ` hover:w-full ${
                                      isActive &&
                                      'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                    }`
                                  }
                                  to="/admin-portal"
                                >
                                  Admin portal
                                </NavLink>
                              </li>
                            )}
                            <li className="flex items-center mx-2 relative hover:text-primary-shade2 h-[40px]">
                              <NavLink
                                onClick={handleClickActiveDrop}
                                className={({ isActive }) =>
                                  ` hover:w-full ${
                                    isActive &&
                                    'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                  }`
                                }
                                to="/my-account"
                              >
                                My account
                              </NavLink>
                            </li>
                          </ul>
                        )}
                      </li>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {Number(windowSize.width) <= screens.md && (
            <>
              {accessTokenGg && (
                <li className="my-2 mt-6 ml-3 mr-5">
                  <div
                    ref={dropDownRef}
                    onClick={handleClickDropDown}
                    className={`flex items-center rounded-full cursor-pointer hover:text-primary-shade2 before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                      isActiveDropDown ? 'before:w-11 mr-4 text-primary-shade1' : ''
                    }`}
                  >
                    More
                    {isPopupDropDown ? (
                      <img src={iconUpArrow} alt="iconUpArrow" width={20} height={20} />
                    ) : (
                      <img src={iconDownArrow} alt="iconDownArrow" width={20} height={20} />
                    )}
                  </div>
                  {isPopupDropDown && (
                    <ul
                      ref={dropDownMenuRef}
                      className="absolute w-[206px] mt-3 rounded-xl bg-neutral-8 p-3 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                    >
                      <>
                        <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                          <NavLink
                            onClick={handleClickActiveDrop}
                            className={({ isActive }) =>
                              ` hover:w-full ${
                                isActive &&
                                'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                              }`
                            }
                            to="/developer-docs"
                          >
                            Developer docs
                          </NavLink>
                        </li>
                        {(isRoleDeveloper ) && (
                          <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                            <NavLink
                              onClick={handleClickActiveDrop}
                              className={({ isActive }) =>
                                ` hover:w-full ${
                                  isActive &&
                                  'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                }`
                              }
                              to="/developer-portal"
                            >
                              Developer portal
                            </NavLink>
                          </li>
                        )}
                        {isRoleAdmin && (
                          <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                            <NavLink
                              onClick={handleClickActiveDrop}
                              className={({ isActive }) =>
                                ` hover:w-full ${
                                  isActive &&
                                  'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                                }`
                              }
                              to="/admin-portal"
                            >
                              Admin portal
                            </NavLink>
                          </li>
                        )}
                        <li className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]">
                          <NavLink
                            onClick={handleClickActiveDrop}
                            className={({ isActive }) =>
                              ` hover:w-full ${
                                isActive &&
                                'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                              }`
                            }
                            to="/my-account"
                          >
                            My account
                          </NavLink>
                        </li>
                      </>
                    </ul>
                  )}
                </li>
              )}
            </>
          )}
        </ul>
      </div>
      <div className="flex items-center">
        {accessTokenGg || userInfoState ? (
          <>
            {!isRoleAdmin && (
              <ButtonBase
                className="max-w-[94px] h-9 mr-5 bg-secondary-bold3 hover:bg-secondary-bold32 disabled:bg-secondary-3 rounded-lg text-neutral-8 font-semibold text-xs leading-3 flex justify-center"
                disabled={statusRequestAcc}
                onClick={handleAccountRequest}
              >
                Request to Production
              </ButtonBase>
            )}
            <div className="relative">
              <div
                ref={btnRef}
                onClick={handleClick}
                className="flex items-center rounded-full cursor-pointer md:mr-[-42px]"
              >
                <img className="max-w-[40px] h-10 rounded-full" src={avatar} alt="Avatar" />
              </div>
              {isPopup && (
                <div
                  ref={menuRef}
                  className="absolute right-0 mt-2 md:mr-[-40px] rounded-xl bg-neutral-8 p-3 w-60 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
                >
                  <div className="p-2 pb-3 mb-1 border-b text-neutral-3 border-b-neutral-6">
                    <p className="font-semibold text-body1">{`${userInfoState?.firstName} ${userInfoState?.lastName}`}</p>
                    <p className="font-medium text-body3">{userInfoState?.role?.name}</p>
                  </div>
                  <div className="p-1">
                    <ButtonSignOut
                      className="justify-start hover:bg-transparent"
                      onClosePopup={() => setIsPopup(false)}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <ButtonSignIn />
        )}
      </div>
    </header>
  )
}

export default Header
