import ChatlioHeader from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const chatlioRoutes: PageRoute[] = [
  {
    path: '/chatlio',
    element: ChatlioHeader,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
  {
    path: '/chatlio/:id',
    element: ChatlioHeader,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  }
]
export default chatlioRoutes
