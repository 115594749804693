import React from 'react'
import ChatlioConversation from './conversation/index'
const ChatlioConversationPart = () => {
  return (
    <div className="px-20 py-10 md:px-10">
      <ChatlioConversation  />
      <hr className="my-10 border-t-neutral-6" />
    </div>
  )
}

export default ChatlioConversationPart
