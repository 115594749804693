import React, { useEffect, useRef, useState, memo } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import {
  GET_ALL_GA_JOB,
  CANCEL_JOB_BY_ID,
  TRIGGER_JOB_BY_ID,
} from '../../../../queries/adminPortal'
import Loading from '../../../../components/loading'
import { useSession } from '../../../../helpers/checkSession'
import { tableHeaderJobData } from './headers'
import { ActionJobItem, Variables, VariablesGAList } from './interface'
// import UserListDialog from './UserListDialog'
import { CODE_TIMEOUT } from '../../../../constants/statusCode'
import Moment from 'react-moment'
import SearchInput from '../../../principal-account-management/components/searchInput'
import IconDot from '../../../../assets/images/icon-dot.svg'
import IconApprove from '../../../../assets/images/icon-approve.svg'
import IconInformation from '../../../../assets/images/icon-information.svg'
interface Props {
  setIsRefetchList: (refetchUser: boolean) => void
  isRefetchList: boolean
}

import classNames from 'classnames'
import DateFilterPicker from '../../../developer-portal/table/activities-log/component/dateFilterPicker'
import moment from 'moment'
import DialogBasic from '../../../developer-portal/dialog/dialogBasic'
import { notify } from '../../../../components/toastify'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import DetailItemDialog from './detailItemDialog'
import SingleRowActionBase from '../../../../components/partials/singleRowActionBase'

const GAListTable: React.FC<Props> = ({ setIsRefetchList, isRefetchList }) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const subjectFilterRef = useRef<HTMLDivElement>(null)
  const subjectCheckboxRef = useRef<HTMLDivElement>(null)
  const [isTableGaScroll, setIsTableGaScroll] = useState<boolean>(false)
  const [isFocusSearchEmailText, setIsFocusEmailText] = useState<boolean>(false)
  const [isFocusApplicationNumber, setIsFocusApplicationNumber] = useState<boolean>(false)
  const [searchObject, setSearchObject] = useState<Variables>()
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [isOpenModalCancelJob, setIsOpenModalCancelJob] = useState<boolean>(false)
  const [isOpenModalTriggerJob, setIsOpenModalTriggerJob] = useState<boolean>(false)
  const [isOpenModalViewDetailJob, setIsOpenModalViewDetailJob] = useState<boolean>(false)
  const [currentJobItemSelected, setCurrentJobItemSelected] = useState<any>()
  const [showAction, setShowAction] = useState<ActionJobItem>({
    jobId: null,
    isShowAction: false,
  })

  const { timeOutSession } = useSession()

  const {
    data: getGAJob,
    loading: getGaLoading,
    refetch: getGAListRefetch,
    error: getGAListError,
  } = useQuery(GET_ALL_GA_JOB, {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 200,
      page: 1,
    },
  } as VariablesGAList)

  const [cancelJobById, { error: errorCancelJobById }] = useMutation(CANCEL_JOB_BY_ID)
  const [triggerJobById, { error: errorTriggerJobById }] = useMutation(TRIGGER_JOB_BY_ID)

  const pendingJobStatus = ['PENDING', 'START_STREAM']
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)

  useEffect(() => {
    if (!getGAJob) {
      getGAListRefetch()
    }
  }, [getGAJob])

  const handleSearchEmailAndPhone = (value: string) => {
    setSearchObject((state) => {
      return { ...state, searchValue: value.trim(), limit: 200, page: 1 }
    })
  }
  const handleSearchApplicationNumber = (value: string) => {
    setSearchObject((state) => {
      return { ...state, applicationNumber: value.trim(), limit: 200, page: 1 }
    })
  }
  useEffect(() => {
    getGAListRefetch(searchObject)
  }, [searchObject])

  const onHandleClickShowAction = (item: any) => {
    if (showAction.jobId == item.jobId) {
      return
    }
    setShowAction({
      jobId: item.jobId,
      isShowAction: true,
    })
    setCurrentJobItemSelected({ ...item })
  }

  const onHandleClickCancelAction = () => {
    setIsOpenModalCancelJob(true)
  }
  const onHandleConfirmCancelAction = async () => {
    if (currentJobItemSelected && pendingJobStatus.includes(currentJobItemSelected.status)) {
      const dataRes = await cancelJobById({
        variables: {
          jobId: currentJobItemSelected?.jobId,
        },
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_GENERAL_SUCCESS, STATUS.SUCCESS)
        getGAListRefetch({ limit: 200, page: 1 })
      }
      handleCloseConfirmInfoDialog()
    } else {
      handleCloseConfirmInfoDialog()
    }
  }
  const onHandleClickTriggerJobAction = () => {
    setIsOpenModalTriggerJob(true)
  }
  const onHandleClickViewDetailJobAction = () => {
    setIsOpenModalViewDetailJob(true)
  }
  const onHandleConfirmTriggerJobAction = async () => {
    if (currentJobItemSelected && pendingJobStatus.includes(currentJobItemSelected.status)) {
      notify('Requested', STATUS.INFO)
      const dataRes = await triggerJobById({
        variables: {
          jobId: currentJobItemSelected?.jobId,
        },
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_GENERAL_SUCCESS, STATUS.SUCCESS)
      }
      getGAListRefetch({ limit: 200, page: 1 })
      handleCloseConfirmInfoDialog()
    } else {
      handleCloseConfirmInfoDialog()
    }
  }

  const handleCloseConfirmInfoDialog = () => {
    setIsOpenModalViewDetailJob(false)
    setIsOpenModalCancelJob(false)
    setIsOpenModalTriggerJob(false)
  }

  useEffect(() => {
    if (errorCancelJobById?.message || errorTriggerJobById?.message) {
      notify(errorCancelJobById?.message ?? errorTriggerJobById?.message, STATUS.ERROR)
    }
  }, [errorCancelJobById, errorTriggerJobById])

  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, limit: 200, page: 1 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, limit: 200, page: 1 }
    })
  }
  useEffect(() => {
    if (isRefetchList) {
      getGAListRefetch({
        limit: 200,
        page: 1,
      })
      setIsRefetchList(false)
    }
  }, [isRefetchList])

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ jobId: null, isShowAction: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  useEffect(() => {
    const handleClickOutSideFilter = (e: any) => {
      if (
        subjectFilterRef &&
        !subjectFilterRef.current?.contains(e.target) &&
        subjectCheckboxRef &&
        !subjectCheckboxRef.current?.contains(e.target) &&
        e.target.id !== 'filter-ref'
      ) {
        setIsOpenFilter(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutSideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSideFilter)
    }
  }, [isOpenFilter])

  useEffect(() => {
    const clientHeight = document.querySelector('#cus-scrollbar')?.clientHeight
    if (document.querySelector('#cus-scrollbar')?.clientHeight && clientHeight) {
      setIsTableGaScroll(clientHeight >= 588)
    }
  }, [document.querySelector('#cus-scrollbar')?.clientHeight, getGAJob])

  useEffect(() => {
    if (getGAListError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [getGAListError])

  return (
    <div>
      <div className="flex justify-between mb-5">
        <p className="pt-2 text-headline5A text-neutral-1">Email Job Data</p>
        <div className="flex justify-end">
          <div className="pr-6">
            <div className="pt-2 font-semibold text-body1 text-neutral-1">View</div>
          </div>
        </div>
      </div>
      <div className="flex w-full h-[56px] mb-5 grid grid-cols-4 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusSearchEmailText,
            'border-neutral-5': !isFocusSearchEmailText,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusEmailText}
            handleSearch={handleSearchEmailAndPhone}
            placehoderText="Search box email or text no"
            isHandleSearch={false}
          />
        </div>
        <div
          className={classNames('rounded-xl border-[2px]  w-full', {
            'border-neutral-3': isFocusApplicationNumber,
            'border-neutral-5': !isFocusApplicationNumber,
          })}
        >
          <SearchInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocusApplicationNumber}
            handleSearch={handleSearchApplicationNumber}
            placehoderText="Search application number"
            isHandleSearch={false}
          />
        </div>
      </div>
      <div className="z-10 w-full border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
        <ul
          className={`flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px] ${
            isTableGaScroll ? 'pr-20' : ''
          }`}
        >
          {tableHeaderJobData.map((item, idx) => (
            <li className={`${item.minW} relative flex`} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2`}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        {getGaLoading && <Loading className="relative py-6" height={30} width={30} />}
        {!getGAJob?.getAllGaJob?.items?.length && !getGaLoading && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}

        {getGAJob?.getAllGaJob?.items?.length > 0 && !getGaLoading && (
          <div
            id="cus-scrollbar"
            className={`cus-scrollbar overflow-y-auto mt-5 mb-3 ${
              isTableGaScroll ? 'mr-2 overflow-y-auto' : 'mr-0 overflow-y-visible'
            }`}
          >
            <div className={`${isTableGaScroll ? 'pr-2' : 'pr-0'} max-h-[588px]`}>
              {getGAJob?.getAllGaJob?.items?.map((item: any, idx: number) => (
                <ul
                  className="flex justify-between px-6 py-3 mb-3 text-base font-medium font-montserrat text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1"
                  key={idx}
                >
                  <li
                    className={tableHeaderJobData[0].minWRow}
                    style={{ maxWidth: tableHeaderJobData[0].maxWidth }}
                  >
                    {item.jobId}
                  </li>
                  <li
                    className={tableHeaderJobData[1].minWRow}
                    style={{ maxWidth: tableHeaderJobData[1].maxWidth }}
                  >
                    {item.jobName}
                  </li>
                  <li
                    className={tableHeaderJobData[2].minWRow}
                    style={{ maxWidth: tableHeaderJobData[2].maxWidth }}
                  >
                    {item.stageDate && <Moment format="MM/DD/YYYY">{item.stageDate}</Moment>}
                  </li>
                  <li
                    className={tableHeaderJobData[3].minWRow}
                    style={{ maxWidth: tableHeaderJobData[3].maxWidth }}
                  >
                    {item?.stageCompleted}
                  </li>
                  <li
                    className={tableHeaderJobData[4].minWRow}
                    style={{ maxWidth: tableHeaderJobData[4].maxWidth }}
                  >
                    {item.status}
                  </li>
                  <li
                    className={tableHeaderJobData[5].minWRow}
                    style={{ maxWidth: tableHeaderJobData[5].maxWidth }}
                  >
                    {item.applicationNumber}
                  </li>
                  <li
                    className={tableHeaderJobData[6].minWRow}
                    style={{ maxWidth: tableHeaderJobData[6].maxWidth }}
                  >
                    {item.email}
                  </li>
                  <li
                    className={tableHeaderJobData[7].minWRow}
                    style={{ maxWidth: tableHeaderJobData[7].maxWidth }}
                  >
                    {item.phoneNumber}
                  </li>
                  <button
                    className="min-w-[24px]  mb-auto"
                    onClick={() => onHandleClickShowAction(item)}
                  >
                    <div className="relative ">
                      <img src={IconDot} alt="Icon-Dot" className="" />
                      {showAction?.jobId === item.jobId && showAction.isShowAction && (
                        <div
                          className={`absolute right-[2px] h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl 'z-10'`}
                          ref={menuRef}
                        >
                          <SingleRowActionBase
                            handleAction={onHandleClickViewDetailJobAction}
                            icon={IconInformation}
                            action="ViewDetail"
                            txt="View Detail"
                          />
                          <SingleRowActionBase
                            handleAction={onHandleClickTriggerJobAction}
                            icon={IconApprove}
                            action="TriggerJob"
                            txt="Trigger Job"
                            block={!pendingJobStatus.includes(item.status)}
                          />
                          <hr className="my-1 border-t-neutral-7" />
                          <SingleRowActionBase
                            handleAction={onHandleClickCancelAction}
                            action="delete"
                            block={!pendingJobStatus.includes(item.status)}
                            txt="Cancel"
                          />
                        </div>
                      )}
                    </div>
                  </button>
                </ul>
              ))}
            </div>
          </div>
        )}
        {isOpenModalViewDetailJob && (
          <DetailItemDialog
            key="detailItemDialog"
            modalIsOpen={true}
            handleCloseDialog={handleCloseConfirmInfoDialog}
            jobData={currentJobItemSelected}
          />
        )}

        <DialogBasic
          modalIsOpen={isOpenModalCancelJob}
          title="Cancel Job"
          confirmation="Are you sure you want to cancel this job?"
          action="Confirm"
          cancel="No"
          handleCloseDialog={handleCloseConfirmInfoDialog}
          handleAction={onHandleConfirmCancelAction}
        />
        <DialogBasic
          modalIsOpen={isOpenModalTriggerJob}
          title="Trigger Job"
          confirmation="Are you sure you want to trigger this job?"
          action="Trigger"
          handleCloseDialog={handleCloseConfirmInfoDialog}
          handleAction={onHandleConfirmTriggerJobAction}
        />
      </div>
    </div>
  )
}

export default memo(GAListTable)
