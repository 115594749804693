import { useQuery } from "@apollo/client"
import { memo, useEffect, useRef, useState } from "react"
import React from 'react'
import { GET_CHATLIO_CONVERSATION, GET_CHATLIO_DATA_TO_DOWNLOAD } from "../../../../queries/adminPortal"
import { VariableFilters, tableHeaderConversation } from "../../interface"
import ConversationItemDialog from "./conversation-detail"
import InfiniteScroll from "react-infinite-scroll-component"
import { SystemMonitoringFilterModel } from "../../../developer-portal/table/activities-log/admin/activitiesMngModels"
import Moment from "react-moment"
import DateFilterPicker from "../../../developer-portal/table/activities-log/component/dateFilterPicker"
import moment from "moment"
import ButtonBase from "../../../../components/partials/button"
import Loading from "../../../../components/loading"
import { useParams } from "react-router-dom"

interface Props {
}

const ChatlioConversation: React.FC<Props> = () => {
  let { id } = useParams()
  const take = SystemMonitoringFilterModel.DEFAULT_OFFSET
  const [isOpenModalViewDetail, setIsOpenModalViewDetail] = useState<boolean>(false)
  const [listChatConversation, setListChatConversation] = useState<any[]>([])
  const [currentItemSelected, setCurrentItemSelected] = useState<any>()
  const [minDateFilter, setMinDateFilter] = useState<Date>()
  const [maxDateFilter, setMaxDateFilter] = useState<Date>()
  const [searchObject, setSearchObject] = useState<VariableFilters>()
  const [hasMoreForAdmin, setHasMoreForAdmin] = useState<boolean>(true)
  const [disableDownload, setDisableDownload] = useState<boolean>(false)
  const [isEmptyCsvData, setIsEmptyCsvData] = useState<boolean>(false)
  const [dataCsv, setDataCsv] = useState<any[]>()
  const {
    data: getChatlioConversation,
    loading: getConversationLoading,
    refetch: getConversationListRefetch,
    error: getConversationListError,
  } = useQuery(GET_CHATLIO_CONVERSATION, {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: take,
      page: 1,
    },
  })
  
  const { 
    refetch: getChatlioDataToDownloadRefetch,
  } = useQuery(GET_CHATLIO_DATA_TO_DOWNLOAD, {
    variables: {
      dateFrom: searchObject?.dateFrom ?? "",
      dateTo: searchObject?.dateTo ?? ""
    },
    skip:  true
  })

  useEffect(() => {
    setHasMoreForAdmin(true)
    setListChatConversation([])
    getConversationListRefetch(searchObject)
  }, [searchObject])

  // Reset data
  useEffect(() => {
    if (!listChatConversation || listChatConversation?.length === 0) {
      setListChatConversation(getChatlioConversation?.getChatlioConversation)
      if (id && id != "") {
        onHandleClickViewDetailAction({id})
        id = ""
      }
    }
  }, [getChatlioConversation])

  useEffect(() => {
    if (isEmptyCsvData) {
      setIsEmptyCsvData(false)
      downloadCsvHandler(dataCsv)
    }
  }, [isEmptyCsvData])
  useEffect(() => {
    if (dataCsv && dataCsv.length > 0) {
      downloadCsvHandler(dataCsv)
       setDataCsv([])
    }
  }, [dataCsv])

  const formatCSVValue = (value: string) => {
    if (!value) {
      return ""
    }
    if (typeof value === 'string' && value.includes(',')) {
      return `"${value.replace(/"/g, '""')}"`
    }
    
    return value 
  }

  const downloadCsvHandler = (dataCsv: any) => {
    // Convert data to CSV format
    const objectKeyCSV = ["createdAt","visitorEmail", "currentPage", "location", "message", "hasFeedback", "textBody", "link"]
    let csvContent = "Date,User Email,Page,Location,First Message, Has Feedback, Messages, Link Detail\n" // CSV header
    dataCsv.map((item: any) => {
      let row = ''
      objectKeyCSV.forEach((key: string) => {
        if (key === 'link') {
          row += formatCSVValue((`${window.location.origin}/chatlio/${item.id}`))
          row += "\n"
        } else {
          row += formatCSVValue(item[key])
          row += ","
        }
      })
      csvContent += row
    })
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob)

    // Create a download link and initiate the file download
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = 'data.csv'
    downloadLink.click()
  }

  const startDateChange = (value: Date) => {
    setMinDateFilter(value)
    const dateFrom = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateFrom, limit: take, page: 1 }
    })
  }
  const endDateChange = (value: Date) => {
    setMaxDateFilter(value)
    const dateTo = value ? moment(value).format('YYYY-MM-DD') : ''
    setSearchObject((state) => {
      return { ...state, dateTo, limit: take, page: 1 }
    })
  }
  const handleCloseConfirmInfoDialog = () => {
    setIsOpenModalViewDetail(false)
  }
  const onHandleClickViewDetailAction = (item: any) => {
    setIsOpenModalViewDetail(true)
    setCurrentItemSelected(item)
  }

  const onHandleDownloadCSVFile = async () => {
    setDisableDownload(true)
    setDataCsv([])
    const response = await getChatlioDataToDownloadRefetch( {
      dateFrom: searchObject?.dateFrom ?? "",
      dateTo: searchObject?.dateTo ?? ""
    })
    const data = response?.data?.getChatlioDataToDownload
    if (data && data.length > 0) {
      setDataCsv(data)
    } else {
      setIsEmptyCsvData(true)
    }
    setDisableDownload(false)
  }

  // fetch more data
  const fetchMoreDataForAdmin = async () => {
    try {
      const currentLength = listChatConversation?.length ?? 0
      const currentPage = Math.floor(currentLength / take)
      if (currentLength > 0 && (currentLength < currentPage * take || currentLength < take)) {
        setHasMoreForAdmin(false)
        return
      }
      const response = await getConversationListRefetch(
        {
          ...searchObject,
          page: currentPage + 1,
        }
      )
      if (!getConversationLoading && !getConversationListError) {
        const data = response.data.getChatlioConversation
        if (data?.length < take) {
          setHasMoreForAdmin(false)
        }
        if (!data?.length || data?.length == undefined) {
          setHasMoreForAdmin(false)
          return
        }
        const dataState = [...listChatConversation, ...data]

        setTimeout(() => {
          setListChatConversation(dataState)
        }, 100)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const btnRefForAdmin = useRef<HTMLDivElement>(null)
  return (
    <div>
      <div className="flex w-full h-[56px] mb-5 grid grid-cols-4 gap-2">
        <DateFilterPicker setCallBackDateChange={startDateChange} maxDate={maxDateFilter} />
        <DateFilterPicker setCallBackDateChange={endDateChange} minDate={minDateFilter} />
        <p></p>
        <ButtonBase
          className=""
          bgHover="bg-violet-900"
          disabled={disableDownload}
          onClick={onHandleDownloadCSVFile}
          bgDisabled="bg-violet-600"
        >
          Download CSV
        </ButtonBase>
      </div>
      <div className="relative overflow-auto border rounded-xl">
      {(getConversationLoading) && <Loading className="relative py-6" height={30} width={30} />}
        <InfiniteScroll
          dataLength={listChatConversation?.length ?? 0}
          next={fetchMoreDataForAdmin}
          hasMore={hasMoreForAdmin}
          height={'max-content'}
          style={{ maxHeight: 480 }}
          loader={''}
          endMessage={''}
          className="cus-scrollbar"
        >
          <table className="w-full text-left">
            <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
              <tr>
                {tableHeaderConversation
                  .map((item, idx) => (
                    <th key={idx} className={`${idx < tableHeaderConversation.length - 1 ? 'py-5 pl-6' : ''
                      } font-semibold  ${item.styles}`}>
                      <div className={`flex`}>
                        <span>
                          {item.name}
                        </span>
                        <div
                          ref={btnRefForAdmin}
                        >
                        </div>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {listChatConversation && listChatConversation.length > 0 && (
                listChatConversation
                  .map((item: any, idx: number) => (
                    <tr key={idx} onDoubleClick={() => onHandleClickViewDetailAction(item)}>
                      <td className={` py-3 pl-6`}>
                        <span >
                          {item?.createdAt && <Moment format="MM/DD/YYYY" utc>{item?.createdAt}</Moment>}
                        </span>
                      </td>
                      <td className={` py-3 pl-6`}>
                        <span >
                          {item?.visitorEmail ?? "-"}
                        </span>
                      </td>
                      <td className={` py-3 pl-6`}>
                        <span >
                          {item?.currentPage ?? "-"}
                        </span>
                      </td>
                      <td className={` py-3 pl-6`}>
                        <span >
                          {item?.location ?? "-"}
                        </span>
                      </td>
                      <td className={` py-3 pl-6`}>
                        <span >
                          {item?.message ?? "-"}
                        </span>
                      </td>
                      <td className="py-3 " >
                        <span >
                          {item?.groupType?.includes("CHAT_TRANSCRIPT") ? "Closed" : "In-progress"}
                        </span>
                      </td>
                    </tr>
                  )))}
            </tbody>
          </table>
        </InfiniteScroll>
        {isOpenModalViewDetail && (
          <ConversationItemDialog
            key='detailItemDialog'
            modalIsOpen={true}
            handleCloseDialog={handleCloseConfirmInfoDialog}
            groupId={currentItemSelected.id}
          />
        )}
      </div>
    </div>
  )
}

export default memo(ChatlioConversation)