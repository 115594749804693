import React, { useEffect, useState } from 'react'
import ButtonBase from '../../components/partials/button'
import EditWebhookSetting from './dialog/editWebhookSetting'
import iconMail from '../../assets/images/icon-mail.svg'
import iconMailWhite from '../../assets/images/icon-mail-white.svg'
import iconPause from '../../assets/images/icon-pause.svg'
import iconPauseWhite from '../../assets/images/icon-pause-white.svg'
import iconUpload from '../../assets/images/icon-upload.svg'
import iconPen from '../../assets/images/icon-pen.svg'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_RETRY_SETTING,
  FIRE_WEBHOOK,
  HOLD_WEBHOOK,
  GET_WEBHOOK_ACTIVE_STATUS,
} from '../../queries/adminPortal'
import Loading from '../../components/loading'
import { MESSAGES } from '../../constants/messages'
import { HandleChangeString } from '../../helpers/functionUtils'
import { useSession } from '../../helpers/checkSession'
import { CODE_TIMEOUT } from '../../constants/statusCode'

const SystemConfiguration = () => {
  const { timeOutSession, handleErrorSession } = useSession()
  const [isOpenEditWebhookSetting, setIsOpenEditWebhookSetting] = useState(false)
  const [isHolding, setIsHolding] = useState(false)
  const [sumValue, setSumValue] = useState<number>(0)
  const [isRefetch, setIsRefetch] = useState<boolean>(false)

  // get last read record and activities log
  const { loading, data, refetch, error } = useQuery(GET_RETRY_SETTING, { fetchPolicy: 'no-cache' })
  const {
    loading: getStatusLoading,
    data: dataGetStatus,
    error: dataGetStatusError,
  } = useQuery(GET_WEBHOOK_ACTIVE_STATUS, {
    fetchPolicy: 'no-cache',
  })
  const [fireWebhook] = useMutation(FIRE_WEBHOOK)
  const [holdWebhook] = useMutation(HOLD_WEBHOOK)

  const handleCloseEditWebhookSetting = () => {
    setIsOpenEditWebhookSetting(false)
  }

  const handleFiringWebhook = async (status: string) => {
    setIsHolding(true)
    if (status === 'fire') setIsHolding(false)
    try {
      const dataRes = status === 'fire' ? await fireWebhook() : await holdWebhook()
      if (dataRes) {
        setIsHolding(!isHolding)
      }
    } catch (error) {
      setIsHolding(false)
      handleErrorSession(error, MESSAGES.ERROR.E_SOMETHING_WENT_WRONG)
    }
  }

  // hidden scroll when dialog is opened
  useEffect(() => {
    if (isOpenEditWebhookSetting) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'overlay'
    }
  }, [isOpenEditWebhookSetting])

  useEffect(() => {
    dataGetStatus?.getWebhookActiveStatus ? setIsHolding(false) : setIsHolding(true)
  }, [dataGetStatus])

  useEffect(() => {
    setSumValue(
      (data?.getRetrySetting?.value1 / 60) * data?.getRetrySetting?.try1 +
        data?.getRetrySetting?.value2 * data?.getRetrySetting?.try2 +
        data?.getRetrySetting?.value3 * data?.getRetrySetting?.try3,
    )
  }, [data])

  useEffect(() => {
    if (
      error?.graphQLErrors[0]?.extensions?.code ||
      dataGetStatusError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [error, dataGetStatusError])

  useEffect(() => {
    if (isRefetch) {
      refetch()
      setIsRefetch(false)
    }
  }, [isRefetch])

  if (loading || getStatusLoading) return <Loading />

  const enableClass = 'text-neutral-8 bg-primary-1 px-8'
  const disableClass = 'border-2 border-primary-shade3 text-primary-1 bg-neutral-8'

  return (
    <div className="px-20 md:px-10 py-10">
      <div className="flex justify-between items-center">
        <p className="text-headline5A text-neutral-1">Webhook control</p>
        <div>
          <ButtonBase
            className={`justify-around ml-3 w-[220px] ${!isHolding ? disableClass : enableClass}`}
            onClick={() => handleFiringWebhook('fire')}
            disabled={!isHolding}
            bgDisabled="bg-neutral-8"
          >
            <div className="flex justify-between">
              <img src={!isHolding ? iconMail : iconMailWhite} alt="iconMail" />
              <p className={`${!isHolding && 'text-primary-shade3'}`}>
                {!isHolding ? 'Firing webhook' : 'Fire webhook'}
              </p>
            </div>
          </ButtonBase>
          <ButtonBase
            className={`justify-around ml-3 w-[220px] ${
              isHolding ? `${disableClass} px-4` : enableClass
            }`}
            onClick={() => handleFiringWebhook('hold')}
            disabled={isHolding}
            bgDisabled="bg-neutral-8"
          >
            <div className="flex justify-between">
              <img src={isHolding ? iconPause : iconPauseWhite} alt="iconHold" />
              <p className={`${isHolding && 'text-primary-shade3'}`}>
                {isHolding ? 'Holding webhook' : 'Hold webhook'}
              </p>
            </div>
          </ButtonBase>
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex justify-between items-center">
        <p className="text-headline5A text-neutral-1">Database management</p>
        <div>
          <ButtonBase
            bgHover="bg-primary-shade1"
            className="justify-around ml-3 w-[295px] text-neutral-8 bg-primary-1"
          >
            <div className="flex justify-between">
              <img src={iconUpload} alt="iconUpload" />
              <p>Upload new records data</p>
            </div>
          </ButtonBase>
        </div>
      </div>
      <hr className="my-10" />
      <div className="flex justify-between items-center mb-5">
        <p className="text-headline5A text-neutral-1">Webhook setting</p>
        <div>
          <ButtonBase
            bgHover="bg-primary-shade1"
            className="justify-around ml-3 w-[118px] text-neutral-8 bg-primary-1"
            onClick={() => setIsOpenEditWebhookSetting(true)}
          >
            <div className="flex justify-between">
              <img src={iconPen} alt="iconPen" width={20} height={20} />
              <p>Edit</p>
            </div>
          </ButtonBase>
        </div>
      </div>
      {/* TABLE */}
      <div className="overflow-auto relative border rounded-xl">
        <table className="w-full text-left">
          <thead className="bg-primary-shade4 text-body1 text-neutral-2">
            <tr>
              <th scope="col" className="font-semibold py-5 pl-6 w-[50%]">
                Timeout
              </th>
              <th className="font-semibold py-5 px-2 w-[25%]">Value</th>
              <th className="font-semibold py-5 pr-6 pl-2 whitespace-nowrap w-[25%]">Try times</th>
            </tr>
          </thead>
          <tbody className="text-body2 text-neutral-3">
            <tr className="h-3"></tr>
            <tr className="border-b-neutral-6 border-b h-[72px]">
              <td className="py-2 pl-6">
                <p>Webhook no response timeout</p>
                <p>{`(0 second < x <= 15 seconds)`}</p>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.timeOut}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.timeOut, 'Second')}
                  </span>
                </div>
              </td>
              <td></td>
            </tr>
            <tr className="border-b-neutral-6 border-b h-[72px]">
              <td className="py-2 pl-6">
                <p>Waiting time between retries</p>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.value1}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.value1, 'Minute')}
                  </span>
                </div>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.try1}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.try1, 'Time')}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="border-b-neutral-6 border-b h-[72px]">
              <td className="py-2 pl-6">
                <p>Waiting time between next layer of retries</p>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.value2}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.value2, 'Hour')}
                  </span>
                </div>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.try2}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.try2, 'Time')}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="border-b-neutral-6 border-b h-[72px]">
              <td className="py-2 pl-6">
                <p>Waiting time between last layer of retries</p>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.value3}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.value3, 'Hour')}
                  </span>
                </div>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {data?.getRetrySetting?.try3}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(data?.getRetrySetting?.try3, 'Time')}
                  </span>
                </div>
              </td>
            </tr>
            <tr className="h-[72px]">
              <td className="py-2 pl-6">
                <p>
                  Mark webhook as not functioning and stop trying to&nbsp;
                  <br className="mw1023:hidden" />
                  call the website again
                </p>
              </td>
              <td className="px-2">
                <div className="flex justify-between">
                  <span className="min-w-[15%] pr-2 font-semibold">
                    {Number.isNaN(sumValue) ? 0 : sumValue.toFixed(0)}
                  </span>
                  <span className="w-[85%] font-medium">
                    {HandleChangeString(Number(sumValue.toFixed(0)), 'Hour')}
                  </span>
                </div>
              </td>
              <td></td>
            </tr>
            <tr className="h-3"></tr>
          </tbody>
        </table>
      </div>
      <hr className="my-10" />
      <EditWebhookSetting
        modalIsOpen={isOpenEditWebhookSetting}
        handleCloseDialog={handleCloseEditWebhookSetting}
        dataSetting={data}
        setIsRefetch={setIsRefetch}
      />
    </div>
  )
}

export default SystemConfiguration
