import { RoleType } from './interface'

export const DEFAULT_LENGTH_JSON_RENDER = 200
export const ROLE: RoleType = {
  Admin: 'Admin',
  Developer: 'Developer',
  AccountManager: 'Account Manager',
  DeveloperAccountManager: 'Developer - Account Manager',
}

export const KIND = {
  CARRIERS: 'carriers',
  AGENCIES: 'agencies',
  ALL: 'all',
  ADMIN: 'admin',
}

export const ERROR_RES = {
  ENTITY_ALREADY: 'Entity already exists.',
}

export const styleJsonPacket = {
  display: '-webkit-box',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
} as object

export const SubjectActionType = [
  { id: 'TEXT_EMAIL', name: 'Renewals Text and Email' },
  { id: 'TEXT', name: 'Renewals Text' },

  { id: 'EMAIL', name: 'Renewals Email' },
]
export const ConfigurationAvoidKeyName = [
  { id: 'EMAIL', name: 'Email' },
  { id: 'PHONE_NUMBER', name: 'Phone Number' },
]

export const ConfigurationDateSettingKeyName = [
  { id: 't1', name: 't1' },
  { id: 't2', name: 't2' },
  { id: 't3', name: 't3' },
  { id: 't4', name: 't4' },
  { id: 't5', name: 't5' },
  { id: 't6', name: 't6' },
  { id: 't7', name: 't7' },
  { id: 't8', name: 't8' },
  { id: 't9', name: 't9' },
  { id: 't10', name: 't10' },
]

export const ConfigurationTypeName = [
  { id: 'AVOID_SETTING', name: 'Avoid Setting', display: 'Avoid' },
  { id: 'DATE_SETTING', name: 'Date Setting', display: 'T-Day' },
]
